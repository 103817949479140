import { Button, Typography } from "antd";
import "antd/dist/antd.css";
import { navigate } from "gatsby";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { config } from "../core/config";
import { MetaInfo } from "../components/MetaInfo";

const LoginPage: FunctionComponent = () => {
  const onLogin = () => {
    return navigate(`${config.apiURL}/auth/login`);
  };

  return (
    <LoginPageWrapper>
      <MetaInfo />
      <Wrapper>
        <Typography.Title level={1}>Login Page</Typography.Title>
        <Button onClick={onLogin}>Login</Button>
      </Wrapper>
    </LoginPageWrapper>
  );
};

export default LoginPage;

const LoginPageWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
